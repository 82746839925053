import { Calendar, Image } from 'antd'
import React, { useEffect, useState } from 'react'
import "../css/booking.css"
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { DateTime } from "luxon";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import dayjs from 'dayjs';



function Booking() {
    const { email } = useParams();

    // Extract query parameters
    const [searchParams] = useSearchParams();
    const dateeee = searchParams.get('date');

    // console.log(dateeee)
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    // Initialize state with current year and month
    const currentDate = new Date();
    const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth());



    // Function to get the number of days in a month
    const daysInMonth = (month, year) => {
        return new Date(year, month + 1, 0).getDate();
    };

    // Function to generate an array of dates for a month
    const generateDates = (month, year) => {
        const numDays = daysInMonth(month, year);
        const firstDayOfMonth = new Date(year, month, 1).getDay(); // Get the weekday of the first day of the month (0 - Sunday, 1 - Monday, ...)
        const dates = Array.from({ length: firstDayOfMonth }, (_, i) => ''); // Create empty cells for days before the first day of the month
        for (let i = 1; i <= numDays; i++) {
            dates.push(i);
        }
        return dates;
    };


    // Function to chunk the dates into weeks
    const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
            chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
    };


    const [eventyear, seteventyear] = useState("")
    const [eventmonth, seteventmonth] = useState("")




    const generateDays = (month, year) => {
        const numDays = daysInMonth(month, year);
        return Array.from({ length: numDays }, (_, i) => i + 1);
    };
    const days = generateDays(eventmonth, eventyear);

    const timeZones = Intl.supportedValuesOf('timeZone'); // Modern browser support
    // Alternatively, use a pre-defined array for wider compatibility if needed.

    const [selectedTimeZone, setSelectedTimeZone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone // Set default to user's current time zone
    );

    const handleTimeZoneChange = (event) => {
        setSelectedTimeZone(event.target.value);
    };



    const [info, setinfo] = useState(false)
    const [timee, settimee] = useState(false)



    const [meeting, setMeetings] = useState([]);
    const [users, setUsers] = useState([]);






    const getmeetbyemail = async () => {


        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("email", email);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getmeetschudulebyemail", requestOptions)
            .then((response) => response.json())
            .then(async (result) => {

                await setMeetings(result)




            })



            .catch((error) => console.error(error));



        // await setUsers(userData);

    }



    useEffect(() => {
        getmeetbyemail()
    }, [])




    const getUser = async (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("_id", id);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        try {
            const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileinfo", requestOptions);
            return await response.json();
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    };




    useEffect(() => {
        const fetchUserDetails = async () => {
            if (meeting && meeting.id) { // Check if meeting data exists and has an id
                const userData = await getUser(meeting.id);

                setUsers(userData); // Set user data as an array with one item
            }

        };

        fetchUserDetails();

    }, [meeting]);


    const [alldays, setalldays] = useState([])


    useEffect(() => {
        if (meeting && meeting.rangedate && meeting.rangedate.length === 2) {
            const startDate = new Date(meeting.rangedate[0]);
            const endDate = new Date(meeting.rangedate[1]);
            const daysArray = [];

            let currentDate = startDate;
            while (currentDate <= endDate) {
                daysArray.push(currentDate.toISOString().split('T')[0]); // Format date as 'YYYY-MM-DD'
                currentDate.setDate(currentDate.getDate() + 1);
            }

            setalldays(daysArray);
        }
    }, [meeting]);


    const [clickday, setclickday] = useState("nill")
    const [alltime, setalltime] = useState([])

    const [selectedalltime, setselectedalltime] = useState("")

    const [formData, setFormData] = useState(() => {
        const initialData = {};
        const fields = meeting?.Fields || [];
        fields.forEach(field => {
            initialData[field.title] = field.type === 'checkbox' ? false : '';
        });
        return initialData;
    });

    const handleChange = (title, value) => {
        setFormData(prevData => ({
            ...prevData,
            [title]: value
        }));
    };

    const [loading, setloading] = useState(false)
    const getformdata = async (e) => {
        e.preventDefault()
        await setloading(true)
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("date", clickday);
        urlencoded.append("meetingid", meeting._id);
        urlencoded.append("time", selectedalltime);
        urlencoded.append("details", JSON.stringify(formData));


        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getdetailstobook", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                setshowal(true)
            })
            .catch((error) => console.error(error));
        await setloading(false)

    }


    const [oppi, setoppi] = useState([])


    const getmeet = async () => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("id", users._id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getbookingmeetbyuserid", requestOptions)
            .then((response) => response.json())
            .then((result) => setoppi(result))
            .catch((error) => console.error(error))

    }

    useEffect(() => {
        // const interval = setInterval(() => {
        getmeet();
        // }, 1000); // Run every 1000ms (1 second)



        // return () => clearInterval(interval); // Clear interval on component unmount
    }, [users]);












    const handleConvertTime = (inputTime) => {

        // Parse the input time as UTC
        const utcTime = DateTime.fromFormat(inputTime, "hh:mm a", { zone: "Europe/London" });

        // Convert to the selected time zone
        const targetTime = utcTime.setZone(selectedTimeZone);

        // Format the time for display



        return (targetTime.toFormat("hh:mm a"));
    };

    const navigate = useNavigate()

    const [showal, setshowal] = useState(false)

    const Alert = () => {

        return (<>


            <div className='alert' onClick={() => navigate("/")}>


            </div>
            <div className='alertbox'>
                <div>
                    Thank you for booking an appointment with us! Further details have been shared with you via email. Please check your inbox for more information.
                </div>
                <button onClick={() => navigate("/")} className='formalbtn'>OK</button>
            </div>
        </>)


    }











    const styles = {
        container: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            fontFamily: "Arial, sans-serif",
            fontSize: "16px",
            height: "50px"
        },
        button: {
            background: "none",
            border: "none",
            cursor: "pointer",
            fontSize: "20px",
            color: "#6A1B9A", // Purple color similar to the image
        },
        label: {
            fontSize: "16px",
            color: "#6A1B9A", // Purple color similar to the image
            textAlign: "center",
        },
    };



    const [date, setDate] = useState(new Date(selectedYear, selectedMonth));

    const handlePrev = () => {
        const newDate = new Date(selectedYear, selectedMonth - 1); // Calculate the new date
        setDate(newDate); // Update the date state
        setSelectedYear(newDate.getFullYear()); // Update the year state
        setSelectedMonth(newDate.getMonth()); // Update the month state
    };

    const handleNext = () => {
        const newDate = new Date(selectedYear, selectedMonth + 1); // Calculate the new date
        setDate(newDate); // Update the date state
        setSelectedYear(newDate.getFullYear()); // Update the year state
        setSelectedMonth(newDate.getMonth()); // Update the month state
    };

    const formatDate = (date) => {
        const options = { year: "numeric", month: "long" };
        return date.toLocaleDateString("en-US", options);
    };



    useEffect(() => {
        if (meeting.length !== 0 && dateeee !== null) {
            const thisdate = dateeee

            const newDate = new Date(dateeee); // Calculate the new date
            setDate(newDate); // Update the date state
            setSelectedYear(newDate.getFullYear()); // Update the year state
            setSelectedMonth(newDate.getMonth());



            setclickday(thisdate);
            settimee(true);

            const dayOfWeek = new Date(thisdate).toLocaleString('en-UK', { weekday: 'long' }).toLowerCase(); // e.g., "monday", "tuesday"
            let timeSlots = [];
            let duration = parseInt(meeting.duration); // Duration in minutes

            const createTimeSlots = (startTime, endTime, interval) => {
                let start = new Date(`${thisdate} ${startTime}`);
                let end = new Date(`${thisdate} ${endTime}`);

                if (isNaN(start) || isNaN(end)) {
                    console.error('Invalid date parsing:', startTime, endTime);
                    return;
                }

                let currentTime = start;
                while (currentTime < end) {
                    timeSlots.push(currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
                    currentTime = new Date(currentTime.getTime() + interval * 60000); // Add interval in milliseconds
                }
            };




            if (dayOfWeek === 'monday' && meeting.Mondaytime.length > 1) {
                createTimeSlots(meeting.Mondaytime[0], meeting.Mondaytime[1], duration);
                setalltime(timeSlots);
            } else if (dayOfWeek === 'tuesday' && meeting.Tuesdaytime.length > 1) {
                createTimeSlots(meeting.Tuesdaytime[0], meeting.Tuesdaytime[1], duration);
                setalltime(timeSlots);
            } else if (dayOfWeek === 'wednesday' && meeting.Wednesdaytime.length > 1) {
                createTimeSlots(meeting.Wednesdaytime[0], meeting.Wednesdaytime[1], duration);
                setalltime(timeSlots);
            } else if (dayOfWeek === 'thursday' && meeting.Thursdaytime.length > 1) {
                createTimeSlots(meeting.Thursdaytime[0], meeting.Thursdaytime[1], duration);
                setalltime(timeSlots);
            } else if (dayOfWeek === 'friday' && meeting.Fridaytime.length > 1) {
                createTimeSlots(meeting.Fridaytime[0], meeting.Fridaytime[1], duration);
                setalltime(timeSlots);
            } else if (dayOfWeek === 'saturday' && meeting.Saturdaytime.length > 1) {
                createTimeSlots(meeting.Saturdaytime[0], meeting.Saturdaytime[1], duration);
                setalltime(timeSlots);
            } else if (dayOfWeek === 'sunday' && meeting.Sundaytime.length > 1) {
                createTimeSlots(meeting.Sundaytime[0], meeting.Sundaytime[1], duration);
                setalltime(timeSlots);
            }

        }



    }, [meeting, users, oppi])



    return (
        <div className='mainbooking'>
            {showal && <>   <Alert /></>}
            <div className='mainbookingbox'>
                <div className='bookingleft'>


                    <Image src={users.profilepic} alt='' width={"200px"} height={"200px"} style={{ borderRadius: "50%" }} />

                    <div className='insidebox2' style={{ color: '#6E4E9F' }}>
                        <h1>{users.name} </h1>
                        <p>{users.email}</p>
                        <p >{users.about}</p>
                        <h3>{meeting.duration} minute Meeting</h3>
                    </div>

                </div>

                {info ? (<>
                    {loading ? (<>
                    
                       <div className='detailsform'> <img alt='' src='/empty.gif' width={"100%"} /></div>
                    </>) : (<>
                        <form className='detailsform' onSubmit={(getformdata)}>
                            <h2>Enter your Details</h2>
                            {clickday}<br />
                            {handleConvertTime(selectedalltime)}
                            {meeting.Fields.map((field, index) => {
                                return (
                                    <div key={index}>
                                        {field.type === "select" ? (
                                            <>
                                                <label>{field.title}</label>
                                                <select
                                                    value={formData[field.title] || ''}
                                                    onChange={(e) => handleChange(field.title, e.target.value)}
                                                    required={field.require}
                                                >
                                                    <option disabled value="">Please Select a Value</option>
                                                    {field.option.map((option, idx) => (
                                                        <option key={idx} value={option.value}>{option.value}</option>
                                                    ))}
                                                </select>
                                            </>
                                        ) : field.type === "checkbox" ? (
                                            <>
                                                <div style={{ flexDirection: "row", alignItems: 'center', gap: '10px' }}>
                                                    <input
                                                        style={{ width: '30px', height: "30px" }}
                                                        type="checkbox"
                                                        checked={formData[field.title] || false}
                                                        onChange={(e) => handleChange(field.title, e.target.checked)}
                                                        required={field.require}
                                                    />
                                                    <label>{field.title}</label>
                                                </div> </>
                                        ) : field.type === "textarea" ? (
                                            <>
                                                <label>{field.title}</label>
                                                <textarea
                                                    type={field.type}
                                                    value={formData[field.title] || ''}
                                                    onChange={(e) => handleChange(field.title, e.target.value)}
                                                    required={field.require}
                                                /></>
                                        ) : (
                                            <>
                                                <label>{field.title}</label>
                                                <input
                                                    type={field.type}
                                                    value={formData[field.title] || ''}
                                                    onChange={(e) => handleChange(field.title, e.target.value)}
                                                    required={field.require}
                                                />
                                            </>
                                        )}
                                    </div>
                                );
                            })}

                            <button className='formalbtn'>Schedule Meeting</button>
                            <div className='hover' onClick={() => setinfo(false)}>Back </div>
                        </form>
                    </>)}
                </>) : (<>
                    <div className='bookingright'>

                        <div>



                            <div className="calendar">

                                <h2 align="center" style={{ color: '#6E4E9F' }}>Select A Date</h2>



                                <div style={styles.container}>
                                    <button style={styles.button} onClick={handlePrev}>
                                        <FaArrowLeft />
                                    </button>
                                    <div style={styles.label}>{formatDate(date)}</div>
                                    <button style={styles.button} onClick={handleNext}>
                                        <FaArrowRight />
                                    </button>
                                </div>


                                <table cellSpacing={"5px"}>
                                    <thead>
                                        <tr>
                                            {daysOfWeek.map(day => (
                                                <th key={day}>{day}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {chunkArray(generateDates(selectedMonth, selectedYear), 7).map((week, index) => (
                                            <tr key={index}>
                                                {week.map((date, index) => {
                                                    let test = ""

                                                    let color = {}

                                                    const formattedMonth = String(parseInt(selectedMonth + 1)).padStart(2, '0'); // Zero-pad month
                                                    const formattedDate = String(parseInt(date)).padStart(2, '0'); // Zero-pad day
                                                    const thisdate = `${selectedYear}-${formattedMonth}-${formattedDate}`;

                                                    const formattedDateeeee = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;

                                                   let dateExists
                                                 
                                                    if (meeting.Spacificday && Array.isArray(meeting.Spacificday)) {
                                                        dateExists = meeting.Spacificday.some(day => day.date === thisdate);

                                                    }

                                                    if (dateExists ) {
                                                        const dateExistssss = meeting.Dateexception.some(day => day === thisdate);

                                                        // alert(formattedDateeeee)
                                                 
                                                        if (!dateExistssss  && formattedDateeeee <= thisdate) {
                                                            test = "shed spacific";
                                                        }
                                                  
                                                  
                                                  
                                                    } else if (alldays.includes(thisdate)   && formattedDateeeee <= thisdate ) {


                                                        const dayOfWeek = new Date(thisdate).toLocaleString('en-US', { weekday: 'long' }).toLowerCase(); // e.g., "monday", "tuesday"

                                                        // Check if `thisdate` matches a weekday in `meeting` with `true` value
                                                        if (
                                                            (dayOfWeek === 'monday' && meeting.Monday) ||
                                                            (dayOfWeek === 'tuesday' && meeting.Tuesday) ||
                                                            (dayOfWeek === 'wednesday' && meeting.Wednesday) ||
                                                            (dayOfWeek === 'thursday' && meeting.Thursday) ||
                                                            (dayOfWeek === 'friday' && meeting.Friday) ||
                                                            (dayOfWeek === 'saturday' && meeting.Saturday) ||
                                                            (dayOfWeek === 'sunday' && meeting.Sunday)
                                                        ) {

                                                            const dateExistssss = meeting.Dateexception.some(day => day === thisdate);

                                                            if (!dateExistssss) {
                                                                test = "shed";
                                                            }

                                                        }
                                                    }


                                                    if (clickday === thisdate) {
                                                        color = { backgroundColor: "#fff", boxShadow: "0 0 1px 3px #6E4E9F", color: "#6E4E9F", fontWeight: "bold" }
                                                    }

                                                    let todayyyyy
                                                    if (thisdate === formattedDateeeee) {
                                                        todayyyyy = "todaydate"
                                                    }

                                                    return (
                                                        <td className={test} style={color} key={index}

                                                            onClick={() => {
                                                                if (test === "shed") {

                                                                    setselectedalltime("")
                                                                    settimee(true);
                                                                    setclickday(thisdate);

                                                                    const dayOfWeek = new Date(thisdate).toLocaleString('en-UK', { weekday: 'long' }).toLowerCase(); // e.g., "monday", "tuesday"
                                                                    let timeSlots = [];
                                                                    let duration = parseInt(meeting.duration); // Duration in minutes





                                                                    function convertTo24HourFormat(time) {
                                                                        const [timePart, modifier] = time.split(' '); // Split time and AM/PM
                                                                        let [hours, minutes] = timePart.split(':').map(Number);

                                                                        if (modifier.toLowerCase() === 'pm' && hours !== 12) {
                                                                            hours += 12; // Convert PM to 24-hour format
                                                                        } else if (modifier.toLowerCase() === 'am' && hours === 12) {
                                                                            hours = 0; // Midnight case
                                                                        }

                                                                        return { hours, minutes };
                                                                    }




                                                                    const createTimeSlots = (startTime, endTime, interval) => {
                                                                        const { hours: startHours, minutes: startMinutes } = convertTo24HourFormat(startTime);
                                                                        const { hours: endHours, minutes: endMinutes } = convertTo24HourFormat(endTime);

                                                                        // Create Date objects
                                                                        let [year, month, day] = thisdate.split('-').map(Number);
                                                                        let start = new Date(year, month - 1, day, startHours, startMinutes, 0); // Explicitly pass hours and minutes
                                                                        let end = new Date(year, month - 1, day, endHours, endMinutes, 0);

                                                                        if (isNaN(start) || isNaN(end)) {
                                                                            console.error('Invalid date parsing:', startTime, endTime);
                                                                            return;
                                                                        }

                                                                        let currentTime = start;
                                                                        while (currentTime < end) {
                                                                            timeSlots.push(formatTimeWithAmPm(currentTime));
                                                                            currentTime = new Date(currentTime.getTime() + interval * 60000); // Add interval in milliseconds
                                                                        }

                                                                        function formatTimeWithAmPm(date) {
                                                                            const hours = date.getHours();
                                                                            const minutes = date.getMinutes();
                                                                            const ampm = hours >= 12 ? 'PM' : 'AM';
                                                                            const formattedHours = (hours % 12 || 12).toString().padStart(2, '0'); // Ensure two-digit hours
                                                                            const formattedMinutes = minutes.toString().padStart(2, '0'); // Ensure two-digit minutes
                                                                            return `${formattedHours}:${formattedMinutes} ${ampm}`;
                                                                        }




                                                                    };

                                                                    if (dayOfWeek === 'monday' && meeting.Mondaytime.length > 1) {



                                                                        createTimeSlots(meeting.Mondaytime[0], meeting.Mondaytime[1], duration);

                                                                        if (meeting.Mondaytime.length > 3) { createTimeSlots(meeting.Mondaytime[2], meeting.Mondaytime[3], duration) }
                                                                        if (meeting.Mondaytime.length > 5) { createTimeSlots(meeting.Mondaytime[4], meeting.Mondaytime[5], duration) }

                                                                        setalltime(timeSlots);





                                                                    } else if (dayOfWeek === 'tuesday' && meeting.Tuesdaytime.length > 1) {
                                                                        createTimeSlots(meeting.Tuesdaytime[0], meeting.Tuesdaytime[1], duration);
                                                                        if (meeting.Tuesdaytime.length > 3) { createTimeSlots(meeting.Tuesdaytime[2], meeting.Tuesdaytime[3], duration) }
                                                                        if (meeting.Tuesdaytime.length > 5) { createTimeSlots(meeting.Tuesdaytime[4], meeting.Tuesdaytime[5], duration) }


                                                                        setalltime(timeSlots);
                                                                    } else if (dayOfWeek === 'wednesday' && meeting.Wednesdaytime.length > 1) {
                                                                        createTimeSlots(meeting.Wednesdaytime[0], meeting.Wednesdaytime[1], duration);
                                                                        if (meeting.Wednesdaytime.length > 3) { createTimeSlots(meeting.Wednesdaytime[2], meeting.Wednesdaytime[3], duration) }
                                                                        if (meeting.Wednesdaytime.length > 5) { createTimeSlots(meeting.Wednesdaytime[4], meeting.Wednesdaytime[5], duration) }

                                                                        setalltime(timeSlots);
                                                                    } else if (dayOfWeek === 'thursday' && meeting.Thursdaytime.length > 1) {
                                                                        createTimeSlots(meeting.Thursdaytime[0], meeting.Thursdaytime[1], duration);
                                                                        if (meeting.Thursdaytime.length > 3) { createTimeSlots(meeting.Thursdaytime[2], meeting.Thursdaytime[3], duration) }
                                                                        if (meeting.Thursdaytime.length > 5) { createTimeSlots(meeting.Thursdaytime[4], meeting.Thursdaytime[5], duration) }


                                                                        setalltime(timeSlots);
                                                                    } else if (dayOfWeek === 'friday' && meeting.Fridaytime.length > 1) {
                                                                        createTimeSlots(meeting.Fridaytime[0], meeting.Fridaytime[1], duration);
                                                                        if (meeting.Fridaytime.length > 3) { createTimeSlots(meeting.Fridaytime[2], meeting.Fridaytime[3], duration) }
                                                                        if (meeting.Fridaytime.length > 5) { createTimeSlots(meeting.Fridaytime[4], meeting.Fridaytime[5], duration) }


                                                                        setalltime(timeSlots);
                                                                    } else if (dayOfWeek === 'saturday' && meeting.Saturdaytime.length > 1) {
                                                                        createTimeSlots(meeting.Saturdaytime[0], meeting.Saturdaytime[1], duration);
                                                                        if (meeting.Saturdaytime.length > 3) { createTimeSlots(meeting.Saturdaytime[2], meeting.Saturdaytime[3], duration) }
                                                                        if (meeting.Saturdaytime.length > 5) { createTimeSlots(meeting.Saturdaytime[4], meeting.Saturdaytime[5], duration) }


                                                                        setalltime(timeSlots);
                                                                    } else if (dayOfWeek === 'sunday' && meeting.Sundaytime.length > 1) {
                                                                        createTimeSlots(meeting.Sundaytime[0], meeting.Sundaytime[1], duration);
                                                                        if (meeting.Sundaytime.length > 3) { createTimeSlots(meeting.Sundaytime[2], meeting.Sundaytime[3], duration) }
                                                                        if (meeting.Sundaytime.length > 5) { createTimeSlots(meeting.Sundaytime[4], meeting.Sundaytime[5], duration) }



                                                                        setalltime(timeSlots);
                                                                    }
                                                                } else if (test === "shed spacific") {



                                                                    const dateExists2 = meeting.Spacificday.find(date => date.date === thisdate);



                                                                    setselectedalltime("")
                                                                    settimee(true);
                                                                    setclickday(thisdate);

                                                                    const dayOfWeek = new Date(thisdate).toLocaleString('en-UK', { weekday: 'long' }).toLowerCase(); // e.g., "monday", "tuesday"
                                                                    let timeSlots = [];
                                                                    let duration = parseInt(meeting.duration); // Duration in minutes

                                                                    function convertTo24HourFormat(time) {
                                                                        const [timePart, modifier] = time.split(' '); // Split time and AM/PM
                                                                        let [hours, minutes] = timePart.split(':').map(Number);

                                                                        if (modifier.toLowerCase() === 'pm' && hours !== 12) {
                                                                            hours += 12; // Convert PM to 24-hour format
                                                                        } else if (modifier.toLowerCase() === 'am' && hours === 12) {
                                                                            hours = 0; // Midnight case
                                                                        }

                                                                        return { hours, minutes };
                                                                    }




                                                                    const createTimeSlots = (startTime, endTime, interval) => {
                                                                        const { hours: startHours, minutes: startMinutes } = convertTo24HourFormat(startTime);
                                                                        const { hours: endHours, minutes: endMinutes } = convertTo24HourFormat(endTime);

                                                                        // Create Date objects
                                                                        let [year, month, day] = thisdate.split('-').map(Number);
                                                                        let start = new Date(year, month - 1, day, startHours, startMinutes, 0); // Explicitly pass hours and minutes
                                                                        let end = new Date(year, month - 1, day, endHours, endMinutes, 0);

                                                                        if (isNaN(start) || isNaN(end)) {
                                                                            console.error('Invalid date parsing:', startTime, endTime);
                                                                            return;
                                                                        }

                                                                        let currentTime = start;
                                                                        while (currentTime < end) {
                                                                            timeSlots.push(formatTimeWithAmPm(currentTime));
                                                                            currentTime = new Date(currentTime.getTime() + interval * 60000); // Add interval in milliseconds
                                                                        }

                                                                        function formatTimeWithAmPm(date) {
                                                                            const hours = date.getHours();
                                                                            const minutes = date.getMinutes();
                                                                            const ampm = hours >= 12 ? 'PM' : 'AM';
                                                                            const formattedHours = (hours % 12 || 12).toString().padStart(2, '0'); // Ensure two-digit hours
                                                                            const formattedMinutes = minutes.toString().padStart(2, '0'); // Ensure two-digit minutes
                                                                            return `${formattedHours}:${formattedMinutes} ${ampm}`;
                                                                        }




                                                                    };

                                                                    // if (dayOfWeek === 'monday' && meeting.Mondaytime.length > 1) {
                                                                    createTimeSlots(dateExists2.time[0], dateExists2.time[1], duration);
                                                                    if (dateExists2.time.length > 3) { createTimeSlots(dateExists2.time[2], dateExists2.time[3], duration) }
                                                                    if (dateExists2.time.length > 5) { createTimeSlots(dateExists2.time[4], dateExists2.time[5], duration) }


                                                                    setalltime(timeSlots);
                                                                    // }

                                                                    // console.log(dateExists2)





                                                                }
                                                            }}


                                                        >
                                                            {/* {thisdate === formattedDateeeee && <>sf</>} */}
                                                            {/* {thisdate} */}
                                                            {/* {test} */}
                                                            <div className={todayyyyy}>
                                                                {date}
                                                            </div>
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>



                                <h3 style={{ marginTop: '20px', color: '#6E4E9F' }}>Time zone</h3>
                                <select className='timezone' value={selectedTimeZone} onChange={handleTimeZoneChange}>
                                    {timeZones.map((zone, index) => (
                                        <option key={index} value={zone}>
                                            {zone}
                                        </option>
                                    ))}
                                </select>



                                {clickday !== "nill" && <>
                                    <div className='confirmbtn2'> <button className='formalbtn  styleeee' >SELECTED DATE: <strong style={{ fontWeight: '900', color: '#6E4E9F' }}>{clickday}</strong></button></div>
                                </>}
                            </div>



                        </div>
                    </div>
                    {timee && <>
                        <div className='selecttime'>

                            <div className='times'>
                                <h3>Select Time</h3>

                                <div className='times2'>
                                    {/* <div className='timeboxselected'>  <div className='timebox sele' >3:30</div> <button onClick={() => setinfo(true)} className='formalbtn'>NEXT</button></div> */}
                                    {alltime.map((value) => {
                                        const isDisabled = oppi.some(item => item.date === clickday && item.time === value);



                                        return (
                                            <>
                                                {isDisabled ? (
                                                    <div className='timebox gray' style={{ pointerEvents: "none", border: '1px solid #E4EDF5', backgroundColor: "#E4EDF5", color: "#aed3f2" }}> <h3>{handleConvertTime(value)}</h3></div>
                                                ) : (
                                                    selectedalltime === value ? (

                                                        <div className='timebox sele'>    <h3>  {handleConvertTime(value)}</h3> </div>


                                                    ) : (
                                                        <div className='timebox' onClick={() => setselectedalltime(value)}>

                                                            <h3>   {handleConvertTime(value)}</h3>

                                                        </div>
                                                    )
                                                )}
                                            </>
                                        );
                                    })}
                                </div>
                                {/* <div className='timebox' onClick={() => setselectedalltime("02:30 AM")}>

                                    {handleConvertTime("02:30 AM")}

                                </div>

                                <div className='timebox' onClick={() => setselectedalltime("02:30 AM")}>

                                    {handleConvertTime("02:30 AM")}

                                </div> */}

                            </div>
                            {selectedalltime.length !== 0 && <>
                                <div className='confirmbtn'> <button onClick={() => { setinfo(true) }} className='formalbtn'>CONFIRM AND NEXT</button></div>
                            </>}
                        </div>

                    </>}
                </>)}
            </div>
        </div>
    )
}

export default Booking
